<template>
    <div class="scrap">
        <div class="header">
            <span @click="gouot"></span>
            <span>车辆进厂地磅称重</span>
            <span @click="islogout">退出</span>
        </div>
        <div class="scrap-content">

            <van-form label-width="130px" label-align="right">
                <van-row :style="{}">
                    <van-col span="24"
                        :style="{ color: 'white', height: '30px', backgroundColor: '#000000', textAlign: 'left', lineHeight: '30px', paddingLeft: '30px', marginTop: '30px' }">
                        地磅称重数值:
                    </van-col>
                </van-row>
                <van-row>
                    <van-col span="24"
                        :style="{ color: '#90ee90', height: '60px', backgroundColor: '#000000', fontSize: '30px', textAlign: 'center', lineHeight: '60px' }">
                        {{ form.weight == '无数据' || form.weight == '等待新信号' ? form.weight : form.weight + 'Kg' }}
                    </van-col>
                </van-row>
                <van-row>
                    <van-col span="24"
                        :style="{ color: 'white', height: '40px', backgroundColor: '#000000', fontSize: '20px', textAlign: 'center', lineHeight: '40px' }">
                        地磅状态：{{ form.weightStatus }}
                    </van-col>
                </van-row>
                <van-row :style="{ marginTop: '10px' }">
                    <van-col span="24"
                        :style="{ color: 'white', height: '30px', backgroundColor: '#000000', textAlign: 'left', lineHeight: '30px', paddingLeft: '30px' }">
                        自动车辆编码:
                    </van-col>
                </van-row>
                <van-row>
                    <van-col span="24"
                        :style="{ color: 'white', height: '70px', backgroundColor: '#000000', fontSize: '40px', textAlign: 'center', lineHeight: '70px' }">
                        {{ form.defined_num }}
                    </van-col>
                </van-row>

                <van-row :style="{ marginTop: '10px' }">
                    <van-col span="24"
                        :style="{ color: 'white', height: '30px', backgroundColor: '#000000', textAlign: 'left', lineHeight: '30px', paddingLeft: '30px' }">
                        输入车牌号码:
                    </van-col>
                </van-row>
                <van-row>
                    <van-col span="24"
                        :style="{ color: 'white', backgroundColor: '#000000', fontSize: '40px', textAlign: 'center' }">
                        <div
                            :style="{ paddingTop: '20px', paddingLeft: '30px', paddingRight: '30px', paddingBottom: '30px' }">
                            <input hidden type="file" ref="filesRef" accept="image/*" @change="afterRead" />
                            <van-field :style="{ border: '0px solid green', borderBottomWidth: '1px' }"
                                v-model="form.carNumberPlate" name="" label="" placeholder="请输入车牌号">
                                <van-button slot="button" size="small"
                                    @click="isWechat ? WeChooseImage(1, '45Degrees') : $refs.filesRef.click()"
                                    type="info">车牌识别</van-button>
                            </van-field>
                        </div>
                    </van-col>
                </van-row>


                <van-row :style="{ marginTop: '10px' }">
                    <van-col span="24"
                        :style="{ color: 'white', height: '30px', backgroundColor: '#000000', textAlign: 'left', lineHeight: '30px', paddingLeft: '30px' }">
                        输入扣杂总量:
                    </van-col>
                </van-row>
                <van-row>
                    <van-col span="24"
                        :style="{ color: 'white', backgroundColor: '#000000', fontSize: '40px', textAlign: 'center' }">
                        <div
                            :style="{ paddingTop: '20px', paddingLeft: '30px', paddingRight: '30px', paddingBottom: '30px' }">
                            <van-field :style="{ border: '0px solid green', borderBottomWidth: '1px' }"
                                v-model="form.cutWeight" name="" label="" placeholder="请输入扣杂重量">
                                <van-button slot="button" size="small" type="number" :disabled="true">KG</van-button>
                            </van-field>
                        </div>
                    </van-col>
                </van-row>

                <div style="margin: 50px 16px 16px;">
                    <van-button round block type="info" @click="onSubmit" :disabled="form.weightStatus!='稳定'">确定过磅</van-button>
                </div>
            </van-form>
        </div>
        <van-popup v-model="show" :close-on-click-overlay="false" class="loading"><van-loading
                size="40px">上传中。。。</van-loading></van-popup>
    </div>
</template>
<script>

</script>

<script>
import UploadImg from "../../components/upload_img";
import { Toast ,Dialog} from 'vant'
import { is_post } from "../../Http/api";
import { encipherMent, encryptDecode } from '@/utils/encrypt'
import AppVue from '../../App.vue';
    export default {
        components: {
            UploadImg,
        },
        data() {
            return {
                showDateTime:false,
                 currentDate:new Date(),
                 isCarInfo:true,
                 isPriceInfo:false,
                 isImagesInfo:false,
                 isConnectInfo:false,
                 isTowage:false,
                 uploader:[],
                 show:false,//上传照片加载
                 showMap:false,//地图
                 second_show:false,//验证码
                 content_show:false,//提交完成
                 form:{
                    carNumberPlate:"",//车牌号
                    weight:"无数据",//车重
                    weightStatus:"无数据",//车重
                    defined_num:"未生成",//型号
                    cutWeight:'',//扣杂重量
                 },
                 WXimg:[],
                 wximagelocalids:[],//判断上传张数
                 toast:null,
            }
        },
        created(){
        },
        mounted () {
            this.getAutoDefindNum()
            this.wcConfig()
            let that=this
            this.GLOBALBUS.$on('watch_WS_MSG', (skt_message) => {
            // 具体函数内容
                //console.log("收到socket消息",skt_message)
                if(skt_message.code==0){
                    switch(skt_message.data.type){
                        case "login":
                            this.getWeightData()
                            break
                        case "autoWeight":
                            if(skt_message.data.error==""){
                                that.form.weight="无数据"
                                that.form.weightStatus="无数据"
                            }else{
                                let weight=skt_message.data.weight
                                let status=skt_message.data.status
                                that.form.weight=weight
                                if(status){
                                    that.form.weightStatus="稳定"
                                }else{
                                    that.form.weightStatus="正在称重"
                                }
                                if(weight==0){
                                    that.form.weightStatus="无重物"
                                }                                
                            }
                            break
                    }
                }else{
                    switch(skt_message.type){
                        case "autoWeight":
                            if(skt_message.error!=""){
                                that.form.weight="无数据"
                                that.form.weightStatus="无数据"
                            }else{
                                let weight=skt_message.weight
                                let status=skt_message.status
                                that.form.weight=weight
                                if(status){
                                    that.form.weightStatus="稳定"
                                }else{
                                    that.form.weightStatus="正在称重"
                                }
                                if(weight==0){
                                    that.form.weightStatus="无重物"
                                }                                
                            }
                            
                            break
                    }
                }

            })
            this.GLOBALBUS.$on('watch_WS_STATUS', (ws_status) => {
            // 具体函数内容
                if(ws_status){
                    console.log("socket全局连接成功")
                    this.getWeightData()
                }else{
                    console.log("socket全局连接失败")
                }
            })
            this.getWeightData()
        },
         watch: {
            wximagelocalids:{
                handler(newValue, oldValue){
                     if(newValue.length > 0 ){
                        this.toast = Toast.loading({
                            duration: 0, // 持续展示 toast
                            forbidClick: true,
                            message: "上传中..."
                        });
                        this.wxUpload(newValue[0])
                    }else{
                        this.toast.clear();
                    }
                },
                deep: true,
            },
        },
        methods: {
            getWeightData(){
                if(window.WS.readyState){
                    let loginCallBack={
                        platform:9999,
                        shopId:window.localStorage.getItem("shopId"),
                        type:"weightCallBack",
                        id:window.localStorage.getItem("WSID"),
                    }
                    window.WS.send(JSON.stringify(loginCallBack))
                }
            },
            getAutoDefindNum(){
                this.$http.post("/index.php/filter/getAutoDefinedNum", {})
                .then((res) => {
                    if (res.data.code == 0) {
                        this.form.defined_num = res.data.data
                    }
                })
                .catch(() => {

                    
                })
            },
            gouot() {
                this.$router.push('/guide')
            },
            //提交信息
            async onSubmit() {
               
                    if (!this.form.defined_num) {
                        this.$toast.fail('自定义编码不能为空')
                        return false
                    }

                    if (isNaN(this.form.defined_num)) {
                        this.$toast.fail('自动生成自定义编码功能未开启')
                        return false
                    }
                    if (isNaN(this.form.weight) || this.form.weight==0) {
                        this.$toast.fail('重量不能为空')
                        return false
                    }
                    if (!this.form.weightStatus) {
                        this.$toast.fail('请等待地磅稳定')
                        return false
                    }

                    if (isNaN(this.form.cutWeight) && this.form.cutWeight!='') {
                        this.$toast.fail('扣杂重量必须为数字')
                        return false
                    }
                    let data = encipherMent( JSON.stringify(this.form))
                    let param = new URLSearchParams();
                    param.append("value",data);
                    let url=''
                    let confirmMsg=''
                    url='/index.php/index/end_of_life_vehicles/saveWeightPoundOrder'
                    confirmMsg='您确定信息无误并保存磅单？'

                    Dialog.confirm({
                        title: '温馨提示',
                        message: confirmMsg,
                    })
                    .then(async() => {
                        // on confirm
                        const res = await is_post( url,param)
                        if(res.code == 0){
                            this.success()
                        }else{
                            this.$toast.fail(res.msg)
                        }
                    })
                    .catch(() => {
                        // on cancel
                    });                
            },

            success(){
                Dialog.confirm({
                    title: '温馨提示',
                    message: "过磅成功,是否继续过磅？",
                })
                .then(async() => {
                    //this.getWeightData()
                    this.getAutoDefindNum()
                    this.form.carNumberPlate='';
                    this.form.cutWeight=''
                    this.form.weight='等待新信号'
                    this.form.weightStatus="等待新信号"
                })
                .catch(() => {
                    this.gouot()
                });
            },
            //上传照片
            afterRead(e){
                let file=e.target.files[0]
                /* console.log("file",e.target.files[0]) */
                this.show = true
                let params = new FormData(); //创建form对象
                params.append("file", file);
                params.append("w", 200);
                params.append("h", 200);
                this.$http
                    .post("/index.php/index/upload/index", params)
                    .then((res) => {
                        if (res.data.code == 0) {
                      
                       this.CarNumberPlateDiscern(res.data.data)
                    } else {
                        this.show= false
                       
                       this.$toast.fail(res.data.msg)
                    }
                })
                .catch(()=>{
                    
                })
            },
            //识别车牌
            async CarNumberPlateDiscern(img){
                try{
                    let data = encipherMent( JSON.stringify({path:img}))
                    let param = new URLSearchParams();
                    param.append("value",data);
                    const res = await is_post( "index.php/vehicles_upload/vehicleCarNumberPlate",param)
                    console.log(res)
                    this.form.carNumberPlate = res.data.carNumberPlate
                    this.show = false
                }
                catch{
                    this.show = false
                }
               
            }
        },
    }
</script>

<style lang="scss" scoped>
.scrap {

    // padding: 10px;
    .title {
        text-align: center;
        font-size: 26px;
        margin-top: 10px;
        padding: 10px;
    }

    .scrap-content {
        margin-top: 70px;

        .scrap-content-div {
            margin-bottom: 20px;
            margin: 15px 15px;

            input: {
                border: 0px solid green;
                border-bottom-width: 1px;
            }
        }
    }

    .btn-p {
        margin: 5px;
    }
}

.van-loading {
    height: 75px !important;
    background: rgba(0, 0, 0, 0.7);
}

.accomplish {
    width: 80%;
    font-size: 26px;
    padding: 20px;
    margin-right: 0 !important;

    .accomplish-title {
        text-align: center;
    }

    .accomplish-zi {
        text-indent: 2em;
    }
}

.btn-Vin {
    margin: 10px;

}

.header {
    position: fixed;
    top: 0;
    z-index: 9;
    width: calc(100% - 40px);
    padding: 0 20px;
    background-color: cadetblue;
    height: 1rem;
    line-height: 1rem;
    color: white;
    font-size: 0.4rem;
    display: flex;
    justify-content: space-between;

    span {
        display: inline-block;
    }

    span:nth-child(1) {
        width: 30px;
        height: 30px;
        background-image: url(../../assets/u39.png);
        background-size: 100%;
        margin-top: 8px;
    }
}

.uploaderImg {
    display: inline-block;
    width: 80px;
    height: 80px;
    border: 1px solid #eee;
    position: relative;

    .iconz {
        position: absolute;
        top: 42%;
        left: 38%;

    }
}

.vanImage {
    position: relative;
    margin-right: 10px;
    margin-top: 10px;

    .iconX {
        position: absolute;
        top: 0;
        right: 0;
        font-size: 25px;
        background: #fff;
        color: #000;
    }
}
</style>
